<app-header [isModalLogin]="isModalLogin" [isModalNewUser]="isModalNewUser"
            (onCloseLogin)="closeModalLogin()"
            (onCloseNewUser)="closeModalNewUser()"></app-header>

<div class="text-color mb-24 montserrat">
  <span class="text-color-light underline cursor-pointer montserrat"
        (click)="goTo(schoolData.slug)">{{'text_available_courses' | translate
    }}</span>
  / {{getCourseName(course)}}
</div>

<div class="separator-line mb-32 mt-32"></div>

<div class="course-reservation-section">
  <!--Course-->
  <div class="course-section">
    <div class="rounded-8 course-image-wrapper">
      <div class="wrapper-back rounded-8"></div>
      <img [src]="course?.image !== null ? course?.image : defaultImage"
           class="rounded-8 course-image-img" />

    </div>
    <div class>
      <div
        class="text-light-gray-bs fw-semibold course-image-icon montserrat">
        <img src="assets/icons/prive_ski2x.png"
             *ngIf="course?.course_type==2" />
        <img src="assets/icons/collectif_ski2x.png"
             *ngIf="course?.course_type==1" />
        <ng-container *ngIf="course?.course_type==1">
          {{'colective' | translate }}
        </ng-container>
        <ng-container *ngIf="course?.course_type==2">
          {{'private' | translate }}
        </ng-container>
        <ng-container *ngIf="schoolData && schoolData.sports">
          {{ getSportName(course?.sport_id) }}
        </ng-container>
      </div>
      <div class="text-white fw-semibold text-semibig" style="margin-top:20px">
        {{getCourseName(course)}}
      </div>
    </div>
    <div class="course-content">
      <ng-container *ngIf="course?.course_type==1">
        <div class="text-color text-medium montserrat course-content-title"
             *ngIf="course.is_flexible">
          <img [src]="themeService.calendar">
          {{'text_choose_dates' | translate }}
        </div>
        <div class="text-color text-medium montserrat course-content-title"
             *ngIf="!course.is_flexible">
          <img [src]="themeService.calendar">
          {{'text_availability' | translate }}
        </div>
        <div class="mt-12 course-content-list">
          <div *ngIf="!course.is_flexible">
            <ng-container *ngFor="let date of course?.course_dates">
              <div class="text-color-light pl-32 montserrat"
                   *ngIf="date.active || date.active === 1">·
                {{date.date | date: 'dd-MM-yyyy'}}
                {{date.hour_start.substring(0,5) }} -
                {{date.hour_end.substring(0,5)}}</div>
            </ng-container>
          </div>
          <div *ngIf="course.is_flexible">
            <ng-container *ngFor="let date of course?.course_dates">

              <div class="checkbox-circle pl-32"
                   *ngIf="(date.active || date.active === 1) && isDateValid(date.date, date.hour_start, date.hour_end)">
                <input
                  id="checkbox-course-{{date.id}}"
                  type="checkbox"
                  *ngIf="course?.is_flexible "
                  (change)="selectDate(date.date)" />
                <label class="text-color-light montserrat"
                       for="checkbox-course-{{date.id}}">
                  {{ date.date | date: 'dd-MM-yyyy' }}
                  {{ date.hour_start.substring(0, 5) }} - {{
                    date.hour_end.substring(0, 5) }}
                </label>
              </div>
            </ng-container>
          </div>

        </div>
      </ng-container>
      <ng-container *ngIf="course?.course_type==2">
        <div class="course-content-flex">
          <div>
            <div class="text-color text-medium montserrat course-content-title">
              <img [src]="themeService.calendar">
              {{'text_when_course' | translate }}
            </div>
            <div class="mt-8 course-content-list">
              <div class="text-color-light pl-32 montserrat">{{ getStartDate()
                }} - {{ getEndDate() }}</div>
              <div class="text-color-light pl-32 montserrat">{{
                  getWeekdays(course?.settings) }}</div>
              <div class="text-color-light pl-32 montserrat">
                {{ 'text_max_participants' | translate }}: {{
                  course?.max_participants }}
              </div>
              <div class="text-color-light pl-32 montserrat">
                {{ 'age_from' | translate }} {{
                  course?.age_min }}
              </div>
              <div class="text-color-light pl-32 montserrat">
                {{ 'age_to' | translate }} {{
                  course?.age_max }}
              </div>
            </div>
          </div>
          <div>
            <div class="text-color text-medium montserrat course-content-title">
              <img [src]="themeService.clock">
              {{ 'text_course_duration' | translate }}
            </div>
            <div class="mt-8 course-content-list">
              <div class="text-color-light pl-32 montserrat"
                   *ngIf="!course?.is_flexible">
                {{ course?.duration }}
              </div>
              <div class="text-color-light pl-32 montserrat"
                   *ngIf="course?.is_flexible">
                {{ 'text_from_price' | translate }} {{ course?.duration }}
              </div>
              <div class="text-color-light pl-32 montserrat">
                {{ course?.hour_min }}h - {{ course?.hour_max }}h
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="text-color text-medium fw-medium">{{'text_short_description' |
        translate}}</div>
      <div class="quill-editor"
           [innerHTML]="getShotrDescription(course)" style="all: initial;">
      </div>
      <div class="show-desktop text-color text-medium fw-medium"
           style="margin-top: 10px">{{'text_description' | translate}}</div>
      <div class="quill-editor"
           [innerHTML]="getDescription(course)" style="all: initial;">
      </div>

      <button *ngIf="!showMoreFilters" (click)="showMoreFilters=true"
              class="show-mobile btn-back-grey rounded-8 button-style width-fit-content cursor-pointer mt-24"
              type="button">
        {{'text_more' | translate }}
      </button>

      <ng-container *ngIf="showMoreFilters">
        <div class="show-mobile text-color text-medium fw-medium"
             style="margin-top: 10px">{{'text_description' | translate}}</div>
        <div class="show-mobile quill-editor"
             [innerHTML]="getDescription(course)" style="all: initial;">
        </div>

        <button (click)="showMoreFilters=false"
                class="show-mobile btn-back-grey rounded-8 button-style width-fit-content cursor-pointer mt-12"
                type="button">
          {{'text_less' | translate }}
        </button>
      </ng-container>
    </div>
  </div>

  <!--Reservation-->
  <div class="reservation-section">
    <div class="background-color-trans rounded-8 padding-box reservation-box">

      <!-- NOT LOGGED-->
      <ng-container *ngIf="!userLogged">
        <div class="text-color text-medium fw-semibold">
          {{'text_booking' | translate }}
        </div>
        <div class="text-color montserrat">
          {{'text_booking1' | translate }}
        </div>
        <div class="reservation-price-flex">
          <div class="text-medium text-color uppercase">
            {{'text_price' | translate }}
          </div>
          <div class="text-medium text-color">
            <span class="text-medium fw-bold">{{ getCoursePrice(course)
              }}</span> {{ course?.currency }} /{{'date' | translate }}
          </div>
        </div>
        <div>
          <button
            class="btn-back-primary rounded-8 button-style cursor-pointer width-100 mb-12"
            type="button"
            (click)="openModalLogin()">
            {{'text_already_client' | translate }}
          </button>
          <button
            class="btn-back-grey rounded-8 button-style cursor-pointer width-100"
            type="button"
            (click)="isModalNewUser = !isModalNewUser">
            {{'text_new_client' | translate }}
          </button>
        </div>
        <div (click)="goTo(schoolData.slug )"
             class="text-color-light montserrat cursor-pointer reservation-return">
          <img [src]="themeService.arrowReturn">
          {{'text_return_courses' | translate }}
        </div>
      </ng-container>
      <!--/NOT LOGGED-->

      <!-- USER LOGGED-->
      <ng-container *ngIf="userLogged">
        <div class="text-color text-medium fw-semibold">
          {{'text_new_booking' | translate }}
        </div>

        <div class="text-color montserrat">
          <ng-container *ngIf="course?.course_type === 2">
            {{'text_select_user_or_more' | translate }}
            {{course?.max_participants}}
          </ng-container>
          <ng-container *ngIf="!(course?.course_type === 2)">
            {{'text_select_user' | translate }}
          </ng-container>
        </div>

        <div class="reservation-users">
          <div class="reservation-user-box cursor-pointer"
               (click)="selectUser(userLogged.clients[0],course?.course_type)"
               [ngClass]="{'opacity-full': (course?.course_type === 2 && selectedUserMultiple.includes(userLogged?.clients[0])) || (!(course?.course_type === 2) && selectedUser === userLogged?.clients[0])}">
            <app-level-user *ngIf="dataLevels && dataLevels.length"
                            [allLevels]="dataLevels" [selectLevel]="185" [size]="70"
                            [userImage]="userLogged?.clients[0].image || 'assets/avatar.png'"></app-level-user>
            <div
              class="text-color text-small montserrat reservation-user-title">
              <img src="assets/images/gold-crown.png">
              {{userLogged?.clients[0].last_name}}
            </div>
            <div
              *ngIf="(course?.course_type === 2 && selectedUserMultiple.includes(userLogged?.clients[0])) || (!(course?.course_type === 2) && selectedUser === userLogged?.clients[0])"
              class="reservation-user-bullet"></div>
          </div>
          <div class="reservation-user-box cursor-pointer"
               *ngFor="let utilizer of userLogged?.clients[0].utilizers"
               (click)="selectUser(utilizer,course?.course_type)"
               [ngClass]="{'opacity-full': (course?.course_type === 2 && selectedUserMultiple.includes(utilizer)) || (!(course?.course_type === 2) && selectedUser === utilizer)}">
            <app-level-user *ngIf="dataLevels && dataLevels.length"
                            [allLevels]="dataLevels" [selectLevel]="182" [size]="70"
                            [userImage]="themeService.avatarEmpty"></app-level-user>
            <div
              class="text-color text-small montserrat reservation-user-title">
              {{utilizer.last_name}}
            </div>
            <div
              *ngIf="(course?.course_type === 2 && selectedUserMultiple.includes(utilizer)) || (!(course?.course_type === 2) && selectedUser === utilizer)"
              class="reservation-user-bullet"></div>
          </div>
          <div class="reservation-user-box cursor-pointer opacity-full"
               (click)="openModalAddUser()">
            <div class="reservation-user-add">
              <img src="/assets/icons/icons-outline-add.svg">
            </div>
            <div
              class="text-color text-small montserrat reservation-user-title">
              {{'text_add' | translate }} {{'text_user' | translate }}
            </div>
          </div>
        </div>

        <!--COLLECTIVE-->
        <ng-container *ngIf="course?.course_type==1">

          <ng-container *ngIf="selectedUser">
            <div class="text-color montserrat mt-12">
              {{'text_select_level' | translate }}
            </div>

            <div>
              <!--              <div *ngIf="!selectedLevel"
                class="text-color fw-semibold montserrat mt-12">
                {{ 'text_no_level' | translate }}
              </div>-->

              <div *ngIf="selectedLevel" class="rounded-8 level-wrapper"
                   (mouseenter)="showTooltipFilter(0)"
                   (mouseleave)="hideTooltipFilter(0)">
                <div
                  [ngStyle]="{'color': selectedLevel.color}">{{selectedLevel.annotation}}</div>
                <div class="text-color">{{selectedLevel.name}}</div>
                <div class="level-check">
                  <img src="assets/icons/check.png" />
                </div>

                <div class="tooltip-container-level background-color-trans"
                     *ngIf="tooltipsFilter[0]" [@fadeInOut]>
                  <!--<div *ngFor="let goal of getFilteredGoals(1)">-->
                  <div class="text-small montserrat text-color-light"
                       *ngFor="let goal of selectedLevel.degrees_school_sport_goals">
                    {{goal.name}}
                  </div>
                </div>
              </div>

              <div
                class="text-color montserrat cursor-pointer reservation-return mt-24 mb-24"
                (click)="showLevels=!showLevels">
                <img [src]="themeService.edit">
                {{'text_change_level' | translate }}
              </div>

              <div
                *ngIf="showLevels && course?.availableDegrees && course?.availableDegrees.length > 0"
                class="levels-section">
                <ng-container
                  *ngFor="let level of course?.availableDegrees;let i = index">

                  <div (click)="selectLevel(level)"
                       (mouseenter)="showTooltipFilter(i)"
                       (mouseleave)="hideTooltipFilter(i)"
                       class="rounded-8 level-wrapper cursor-pointer"
                       *ngIf="isAgeAppropriate(transformAge(selectedUser.birth_date), level.age_min, level.age_max)">
                    <div
                      [ngStyle]="{'color': level.color}">{{level.annotation}}</div>
                    <div class="text-color">{{level.name}}</div>
                    <div *ngIf="level == selectedLevel" class="level-check">
                      <img src="assets/icons/check.png" />
                    </div>
                    <div class="tooltip-container-level background-color-trans"
                         *ngIf="tooltipsFilter[i]" [@fadeInOut]>
                      <!--<div *ngFor="let goal of getFilteredGoals(1)">-->
                      <div class="text-small montserrat text-color-light"
                           *ngFor="let goal of level.degrees_school_sport_goals">
                        {{goal.name}}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div
                *ngIf="showLevels && course?.availableDegrees && course?.availableDegrees.length && !hasLevelsAvailable">
                <p> {{'text_no_degrees' | translate }}</p>
              </div>
            </div>
          </ng-container>

        </ng-container>

        <!--PRIVATE-->
        <ng-container *ngIf="course?.course_type==2">

          <ng-container
            *ngIf="selectedUserMultiple && selectedUserMultiple.length">
            <div class="text-color montserrat mt-12 margin-auto">
              {{'text_select_date_disponibility' | translate }}
            </div>

            <!--Calendar Private-->
            <div class="calendar-reservation-section margin-auto">
              <div class="calendar background-color-trans rounded-8">
                <div class="header-calendar">
                  <div class="header-calendar-arrows">
                    <img [src]="themeService.arrowLeft" (click)="prevMonth()" />
                    <img [src]="themeService.arrowRight"
                         (click)="nextMonth()" />
                  </div>
                  <div class="text-color text-semibig fw-medium">{{
                      monthNames[currentMonth] }}</div>
                  <div class="text-color text-semibig fw-medium">{{ currentYear
                    }}</div>
                </div>
                <div class="weekdays">
                  <span *ngFor="let day of weekdays"
                        class="montserrat text-small text-color-light">{{ day
                    }}</span>
                </div>
                <div class="days">
                  <ng-container *ngFor="let day of days">
                    <span [ngClass]="{'very-light-opacity': day.past}"
                          class="montserrat day-span-a"
                          (click)="selectDay(day)">
                      <span [ngClass]="{
                                                'active-collective': day.active && course.course_type == 1,
                                                'active-collective-selected': day.selected && course.course_type == 1,
                                                'active-private': day.active  && course.course_type == 2,
                                                'active-private-selected': day.selected  && course.course_type == 2
                                                }"
                            class="day-span-b text-color">
                        {{ day.number }}
                      </span>
                    </span>
                  </ng-container>
                </div>
              </div>
            </div>

            <ng-container *ngIf="selectedDateReservation">
              <div class="calendar-reservation-form">
                <div
                  class="text-color text-medium montserrat cursor-pointer reservation-return mt-12 mb-24 margin-auto">
                  <img [src]="themeService.calendar">
                  {{selectedDateReservation}}
                </div>

                <div class="text-color-light montserrat mt-12 margin-auto">
                  {{ 'text_level' | translate }}
                </div>
                <div
                  class="levels-section">
                  <ng-container
                    *ngFor="let level of dataLevels;let i = index">

                    <div (click)="selectLevel(level)"
                         (mouseenter)="showTooltipFilter(i)"
                         (mouseleave)="hideTooltipFilter(i)"
                         class="rounded-8 level-wrapper cursor-pointer"
                         *ngIf="isGroupAgeAppropriate(selectedUserMultiple, level.age_min, level.age_max)">
                      <div
                        [ngStyle]="{'color': level.color}">{{level.annotation}}</div>
                      <div class="text-color">{{level.name}}</div>
                      <div *ngIf="level == selectedLevel" class="level-check">
                        <img src="assets/icons/check.png" />
                      </div>
                      <div class="tooltip-container-level background-color-trans"
                           *ngIf="tooltipsFilter[i]" [@fadeInOut]>
                        <!--<div *ngFor="let goal of getFilteredGoals(1)">-->
                        <div class="text-small montserrat text-color-light"
                             *ngFor="let goal of level.degrees_school_sport_goals">
                          {{goal.name}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="selectedLevel">
                  <div class="text-color montserrat margin-auto">
                    {{'text_select_hour_date' | translate }}
                  </div>

                  <div class="text-color-light montserrat mt-12 margin-auto">
                    {{ 'text_hour' | translate }}
                  </div>
                  <select
                    class="form-control margin-auto calendar-reservation-form-input"
                    [(ngModel)]="selectedHour"
                    (change)="onHourChange($event)">
                    <option *ngFor="let hour of availableHours" [value]="hour">{{ hour }}</option>
                  </select>

                  <div class="text-color-light montserrat mt-12 margin-auto">
                    {{ 'text_duration' | translate }}
                  </div>
                  <ng-container *ngIf="course?.is_flexible">
                    <select
                      class="form-control margin-auto calendar-reservation-form-input"
                      [(ngModel)]="selectedDuration"
                      (change)="updatePrice()">
                      <option *ngFor="let range of availableDurations" [ngValue]="range">{{ range.duration }}</option>
                    </select>
                  </ng-container>
                  <ng-container *ngIf="!course?.is_flexible">
                    <select
                      class="form-control margin-auto calendar-reservation-form-input"
                      [(ngModel)]="selectedDuration">
                      <option *ngFor="let range of availableDurations" [ngValue]="range">{{ range.duration }}</option>
                    </select>
                  </ng-container>
                </ng-container>
              </div>

              <div class="reservation-price-flex mt-12">
                <div class="text-medium text-color uppercase">
                  {{'text_price' | translate }}
                </div>
                <div class="text-medium text-color">
                  <span class="text-color text-medium fw-bold">
                    {{course?.price}}</span> {{ course?.currency }}
                </div>
              </div>
              <div>
              </div>
              <ng-container *ngIf="course?.options">
                <div *ngIf="isForfaitRequired && !selectedForfait"
                     class="error-message">
                  {{ 'forfait_error' | translate }}
                </div>
                <p>Forfait</p>
                <ng-container *ngFor="let extra of settings.extras.forfait">
                  <div class="checkbox-circle pl-32">
                    <input
                      id="radio-course-{{extra.id}}"
                      type="checkbox"
                      name="forfaitSelection"
                      [checked]="selectedForfait === extra"
                      (change)="toggleForfaitSelection(extra)" />
                    <label class="text-color-light montserrat"
                           for="radio-course-{{extra.id}}">
                      {{ extra.name }} - {{ extra.price }}{{ course?.currency }}
                      /
                      {{ 'date' | translate }}
                    </label>
                  </div>
                </ng-container>
                <!-- Mensaje de error si no hay selección y es obligatorio -->
              </ng-container>
              <div>
                <button (click)="addBookingToCart()"
                        [disabled]="(isForfaitRequired && !selectedForfait) || !availableDurations.length || !selectedLevel"
                        class="btn-back-primary rounded-8 button-style-icon cursor-pointer width-100 mb-12"
                        type="button">
                  <img src="/assets/icons/cart.svg"> {{'text_add_to_cart' |
                  translate }}
                </button>
              </div>

            </ng-container>

          </ng-container>

        </ng-container>

        <ng-container *ngIf="course?.course_type ==1">
          <div class="reservation-price-flex mt-12">
            <div class="text-medium text-color uppercase">
              {{'text_price' | translate }}
            </div>
            <div class="text-medium text-color" *ngIf="!course?.is_flexible">
              <span class="text-color text-medium fw-bold">
                {{course?.price}}</span> {{ course?.currency }}
            </div>
            <div class="text-medium text-color" *ngIf="course?.is_flexible">
              <span class="text-color text-medium fw-bold">
                {{collectivePrice}}</span> {{ course?.currency }} /{{'date' |
              translate }}
            </div>
          </div>
          <ng-container *ngIf="course?.options">
            <div *ngIf="isForfaitRequired && !selectedForfait"
                 class="error-message">
              {{ 'forfait_error' | translate }}
            </div>
            <p>Forfait</p>
            <ng-container *ngFor="let extra of settings.extras.forfait">
              <div class="checkbox-circle pl-32">
                <input
                  id="radio-course-{{extra.id}}"
                  type="checkbox"
                  name="forfaitSelection"
                  [checked]="selectedForfait === extra"
                  (change)="toggleForfaitSelection(extra)" />
                <label class="text-color-light montserrat"
                       for="radio-course-{{extra.id}}">
                  {{ extra.name }} - {{ extra.price }}{{ course?.currency }}
                  /
                  {{ 'date' | translate }}
                </label>
              </div>
            </ng-container>
            <!-- Mensaje de error si no hay selección y es obligatorio -->
          </ng-container>
          <div>
            <button (click)="addBookingToCart()"
                    [disabled]="!selectedUser || !selectedLevel || (isForfaitRequired && !selectedForfait)"
                    class="btn-back-primary rounded-8 button-style-icon cursor-pointer width-100 mb-12"
                    type="button">
              <img src="/assets/icons/cart.svg"> {{'text_add_to_cart' |
              translate }}
            </button>
          </div>
        </ng-container>

        <div (click)="goTo(schoolData.slug )"
             class="text-color-light montserrat cursor-pointer reservation-return mt-12">
          <img [src]="themeService.arrowReturn">
          {{'text_delete_return_courses' | translate }}
        </div>

      </ng-container>
      <!--/USER LOGGED-->

    </div>
  </div>
</div>

<app-modal-add-user [slug]="schoolData?.slug" [isOpen]="isModalAddUser"
                    (onClose)="closeModalAddUser()"></app-modal-add-user>
